import React from 'react'
import { Button, Grid, H, P, Wrapper } from '@farewill/ui'
import { Link } from 'gatsby'

import { GENERAL_EMAIL_ADDRESS } from 'config'
import DefaultLayout from 'layouts/DefaultLayout'
import TelephoneNumber from 'components/TelephoneNumber'

const NotFound = () => (
  <DefaultLayout
    title="Page Not Found"
    meta={[
      {
        name: 'robots',
        content: 'noindex,follow',
      },
    ]}
  >
    <Wrapper centered container>
      <H as="h1" decorative size="XL">
        404
      </H>
      <P>Oops! Looks like something isn’t right.</P>
      <P>If you were expecting something, please get in touch.</P>

      <Grid gap="S" margin={['L', 'auto', 'L']} maxWidthInColumns={6}>
        <Grid.Item span={12} spanFromM={6}>
          <Button.Bordered
            href={`mailto:${GENERAL_EMAIL_ADDRESS}`}
            rel="noreferrer"
            stretch
            target="_blank"
          >
            Email us
          </Button.Bordered>
        </Grid.Item>
        <Grid.Item span={12} spanFromM={6}>
          <Button.Primary stretch tag={Link} to="/">
            Go home
          </Button.Primary>
        </Grid.Item>
      </Grid>
      <P>
        Call us on <TelephoneNumber />.
      </P>
    </Wrapper>
  </DefaultLayout>
)

export default NotFound
